export default {
  async findById(invoiceId: number): Promise<Invoice | null> {
    return await apiGet<Invoice>(`billing/invoice/${invoiceId}`).catch((error) => {
      if (error.response?.status == 404) {
        return null
      } else {
        throw error
      }
    })
  },
  async findBySubscriptionId(subscriptionId: number): Promise<Invoice[]> {
    return await apiGet<Invoice[]>(`billing/subscription/${subscriptionId}/invoices`)
  },
  async createAlternativePayment(invoiceId: number, billingPlatformId: number, paymentProfileType: string = 'PIX'): Promise<Invoice> {
    return await apiPost<Invoice>(`billing/invoice/${invoiceId}/alternative-payment`, { body: { billingPlatformId, paymentProfileType } })
  }
}

export type Invoice = {
  tenant: string,
  id: number,
  billingPlatformId: number,
  customerId: number,
  paymentProfileId: number,
  subscriptionId: number | null,
  status: InvoiceStatus,
  currency: string,
  amount: number,
  total: number,
  bankSlipUrl: string | null,
  pix: string | null,
  paidAmount: number,
  dueAmount: number,
  chargeAt: string,
  nextChargetAttemptAt: string | null,
  dueAt: string | null,
  paidAt: string | null,
  createdAt: string,
  updatedAt: string,
}

export enum InvoiceStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  PAID = 'PAID',
  FORGIVEN = 'FORGIVEN',
  UNPAID = 'UNPAID',
  DISPUTED = 'DISPUTED',
  REFUNDED = 'REFUNDED',
  CLOSED = 'CLOSED',
}

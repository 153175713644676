<template lang="pug">
.pix-payment
  .pix(v-if="pix != null")
    p Pix válido por 30 minutos. A confirmação do seu pagamento pode levar alguns minutos.

    .pix-options
      .pix-qrcode
        h4 Pix QR Code
        p Alternativamente, você pode escanear o QR Code abaixo:

        .qrcode-container
          .qrcode
            qrcode-vue(:value="pix", :size="200", level="H")

      .pix-copy-paste
        h4 Pix copia e cola
        p Clique aqui para copiar o seu código Pix:

        .copy-paste(@click="copyPix")
          fa(:icon="['far', 'copy']")
          | &#32;Pix Copia e Cola

        p Ou copie o código abaixo:

        pre {{ pix }}
</template>
<script lang="ts" setup>
import QrcodeVue from 'qrcode.vue'

const props = defineProps({
  pix: {
    type: String
  }
})

const notifications = useNotifications()

const copyPix = () => {
  console.log('copyPix')
  const text = props.pix ?? ""

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)

    notifications.info('Código Pix copiado!')
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()

    try {
      document.execCommand('copy')
      notifications.info('Código Pix copiado!')
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      notifications.error('Não foi possível copiar o código Pix!')
    } finally {
      document.body.removeChild(textarea)
    }
  }
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.pix-payment
  position: relative

  .pix

    .pix-options
      display: flex
      flex-direction: row
      flex-wrap: wrap-reverse
      gap: 16px

      & > *
        width: 360px
        max-width: 100%

        @include tablet
          width: 100%

      .pix-copy-paste

        .copy-paste
          position: relative
          display: block
          width: 240px
          margin: 32px auto
          padding: 16px 32px
          font-size: 16px
          text-align: center
          border-radius: 48px
          background-color: $acquaBlue
          cursor: pointer
          user-select: none

          &::before
            position: absolute
            display: block
            top: 4px
            left: 4px
            width: calc(100% - 8px)
            height: calc(100% - 8px)
            border: dashed 2px black
            border-radius: 48px
            content: ''
            box-sizing: border-box

        pre
          display: block
          margin: 0 auto
          padding: 16px
          font-size: 12px
          text-align: left
          border-radius: 8px
          background-color: $white
          white-space: nowrap
          overflow-y: auto

      .pix-qrcode

        .qrcode-container
          position: relative
          display: block
          width: 296px
          margin: 32px auto
          font-size: 14px
          text-align: center
          border-radius: 8px
          background-color: $beige

          .qrcode
            position: relative
            display: inline-block
            width: 232px
            padding: 16px
            background-color: white
            border-radius: 8px
</style>

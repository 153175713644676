<template lang="pug">
.alternativePaymentPixButton
  Button(
      type="text",
      label="Pagar com Pix",
      @click="generatePix",
      :loading="generatingPix"
    )

  ClientOnly
    Teleport(to="#modals")
      Dialog(
        title="Pagar com Pix",
        v-model:open="pixDialogOpen",
        width="768px"
      )

        h4(style="margin-top: 0") {{ invoiceDescription }}

        p
          b Valor:&nbsp;
          | R${{ centsToString(dueAmount) }}

        PixPayment(v-if="pix", :pix="pix")
</template>
<script setup lang="ts">
import InvoiceService, { type Invoice, InvoiceStatus } from '~/services/invoice/invoiceService'
import { planInstallments, planName, type PrimarySubscription } from '~/services/subscription/subscriptionService'

const props = defineProps({
  invoice: {
    type: Object as PropType<Invoice>,
    required: true
  },
  subscription: {
    type: Object as PropType<PrimarySubscription>,
    required: true
  }
})

const emit = defineEmits(['pixPaid'])

const notifications = useNotifications()

const generatingPix = ref(false)
const pixDialogOpen = ref(false)
const pix = ref<String | null>(null)

const pixInvoice = ref<Invoice | null>(null)

const invoiceDescription = computed(() => {
  if (props.subscription) {
    const plan = planName[props.subscription!!.planId]
    const months = props.subscription!!.recurrence.numberOfPeriods

    if (months > 1) {
      return `Assinatura ${plan} - ${months} meses de assinatura`
    } else {
      return `Assinatura ${plan} - 1 mês de assinatura`
    }
  } else {
    return `Fatura #${props.invoice.id}`
  }
})

const dueAmount = computed(() => {
  return props.invoice.dueAmount
})

const generatePix = async () => {
  generatingPix.value = true

  const i = props.invoice

  try {
    const alternativePaymentInvoice = await InvoiceService.createAlternativePayment(i.id, i.billingPlatformId, 'PIX')

    pixInvoice.value = alternativePaymentInvoice

    const pixValue = alternativePaymentInvoice.pix ?? 'https://boxmagenta.com.br/pix'

    pix.value = pixValue
    pixDialogOpen.value = true

    if (!monitorHandle) {
      monitorInvoicePayment()
    }
  } catch (error) {
    console.error('Failed to generate PIX for invoice', props.invoice.id, error)

    notifications.error('Não foi possível gerar Pix para pagamento. Por favor, tente novamente mais tarde')
  } finally {
    generatingPix.value = false
  }
}

var monitorHandle: ReturnType<typeof setTimeout> | null = null
const monitorInvoicePayment = async () => {
  monitorHandle = setTimeout(async () => {
    const i = pixInvoice.value

    if (!i) {
      return
    }

    const updatedInvoice = await InvoiceService.findById(i.id)

    if (updatedInvoice && updatedInvoice.status === InvoiceStatus.PAID) {
      notifications.success('Pagamento confirmado com sucesso!')

      pixDialogOpen.value = false

      emit('pixPaid')

      monitorHandle = null
    } else {
      monitorInvoicePayment()
    }
  }, 5000)
}

onUnmounted(() => {
  if (monitorHandle) {
    clearTimeout(monitorHandle)

    monitorHandle = null
  }
})
</script>
